const getIntersectionObserver = () =>
  import(
    /* webpackChunkName: "intersection-observer" */
    /* webpackPrefetch: true */
    'intersection-observer'
  )

const getSmoothscroll = () =>
  import(
    /* webpackChunkName: "smoothscroll-polyfill" */
    /* webpackPrefetch: true */
    'smoothscroll-polyfill'
  )

const plugin = () => {
  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style

  if (!('IntersectionObserver' in window)) {
    getIntersectionObserver()
  }

  if (!supportsNativeSmoothScroll) {
    getSmoothscroll()
      .then((module) => module.default.polyfill())
      .catch(() => undefined)
  }
}

export default plugin
