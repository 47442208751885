/* eslint max-classes-per-file: 0 */

import Vue from 'vue'
import debounce from 'lodash/debounce'
import {
  breakpointLarge,
  breakpointDesktop,
  breakpointTablet,
  breakpointMobile,
} from '@/assets/scss/variables.scss'

class ScreenClass {
  width = 0

  height = 0

  name = 'mobile'

  sizes = {
    mobile: parseInt(breakpointMobile, 10),
    tablet: parseInt(breakpointTablet, 10),
    desktop: parseInt(breakpointDesktop, 10),
    large: parseInt(breakpointLarge, 10),
  }

  mobile = false
  tablet = false
  desktop = false
  large = false

  largeAndUp = false
  desktopAndUp = false
  tabletAndUp = false
  mobileAndUp = false

  largeAndDown = false
  desktopAndDown = false
  tabletAndDown = false
  mobileAndDown = false
}

class ScreenPlugin {
  constructor(context, updateDebounce = 16) {
    this.context = context
    this.updateDebounce = updateDebounce
    this.screen = Vue.observable(new ScreenClass(context))
  }

  getInstance() {
    if (process.client) this.start()

    return this.screen
  }

  start() {
    const { screen, updateDebounce } = this
    const target =
      window.visualViewport !== undefined ? window.visualViewport : window

    const update = this.update.bind(this)
    const updateCallback =
      updateDebounce > 0 ? debounce(update, updateDebounce) : update

    target.addEventListener('resize', updateCallback)
    update(true)

    if (screen.name === 'mobile') {
      document.body.classList.add('screen--mobile')
    }
  }

  update(force) {
    const { screen } = this
    const w = window.innerWidth
    const h = window.innerHeight

    if (h !== screen.height) {
      screen.height = h
    }

    if (w !== screen.width) {
      screen.width = w
    } else if (force !== true) {
      return
    }

    const [name, oldName] = this.updateScreen(w)

    if (name !== oldName) {
      document.body.classList.remove(`screen--${oldName}`)
      document.body.classList.add(`screen--${name}`)
    }
  }

  updateScreen(w) {
    const { screen } = this
    const s = screen.sizes

    screen.mobile = w <= s.mobile
    screen.tablet = w > s.mobile && w <= s.tablet
    screen.desktop = w > s.tablet && w <= s.desktop
    screen.large = w > s.desktop

    screen.largeAndUp = w >= s.large
    screen.desktopAndUp = w >= s.desktop
    screen.tabletAndUp = w >= s.tablet
    screen.mobileAndUp = w >= s.mobile

    screen.largeAndDown = w <= s.large
    screen.desktopAndDown = w <= s.desktop
    screen.tabletAndDown = w <= s.tablet
    screen.mobileAndDown = w <= s.mobile

    const name =
      (screen.mobile === true && 'mobile') ||
      (screen.tablet === true && 'tablet') ||
      (screen.desktop === true && 'desktop') ||
      'large'

    const oldName = screen.name
    if (name !== oldName) {
      screen.name = name
    }

    return [name, oldName]
  }
}

export default (context, inject) => {
  const screen = new ScreenPlugin(context).getInstance()
  inject('screen', screen)
}
