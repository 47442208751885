<template>
  <div class="template">
    <Header />
    <main>
      <Nuxt />
    </main>
    <Footer />

    <portal-target name="layout" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { hydrateWhenVisible } from 'vue-lazy-hydration'

const Header = import(/* webpackChunkName: "header" */ '@/components/Header/')
const Footer = import(/* webpackChunkName: "footer" */ '@/components/Footer/')

export default {
  name: 'Default',

  components: {
    Header: hydrateWhenVisible(() => Header),
    Footer: hydrateWhenVisible(() => Footer),
  },

  computed: {
    ...mapState(['isActiveMobileMenu']),
  },

  watch: {
    isActiveMobileMenu(value) {
      if (value) document.body.classList.add('overflow')
      else document.body.classList.remove('overflow')
    },
  },
}
</script>
