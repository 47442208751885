import { setField } from '@/store/helpers'

const state = () => ({
  isActiveMobileMenu: false,
})

const getters = {}

const actions = {
  toggleMobileMenu({ commit }, { value }) {
    commit('setField', {
      field: 'isActiveMobileMenu',
      value,
    })
  },
}

const mutations = {
  setField,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
